export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/migratelogo.jpg',
    banner_img_root : '/img/3margaritas-banner.jpg',
    resid : '',
    key_value : '3margaritas',
    secret_value : '3margaritas',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3096.5619187883613!2d-104.86156028464384!3d39.09367527954071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876cadefa53d418d%3A0xc69ec1b82c029b5a!2s3%20Margaritas%20Monument!5e0!3m2!1sen!2sus!4v1649412508992!5m2!1sen!2sus',
    facebook_link:'#',
    RestaurantName:"3 Margaritas Mexican"
    
};
